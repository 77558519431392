.header {
  display: grid;
  grid-template-columns: 80px auto 90px 45px;
  grid-gap: 20px;
  position: relative;
  font-size: rem(18px);
  padding-top: 35px;
  padding-bottom: 50px;
  @include mq($until: xl) {
    padding-bottom: 30px;
  }
  @include mq($until: lg) {
    grid-template-columns: 70px auto 80px 35px;
    grid-gap: 10px;
  }

  @include mq($until: md){
    grid-template-columns: auto 85px 40px 60px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  @include mq($until: xs){
    grid-template-columns: 70px auto 40px 35px;
  }

  &__logo{
    display: flex;
    align-items: flex-end;
  }

  &__nav{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  &__menuList {
    display: flex;
    margin: 0;
    width: 85%;
    justify-content: space-between;
    @include mq($until: xl) {
      width: 90%;
    }
    @include mq($until: md){
      width: 100%;
    }
  }

  &__menuItem {
    margin-left: 25px;
    padding: 0;
    @include mq($until: lg) {
      margin-left: 10px;
    }
    &:first-of-type{
      margin-left: 0;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #3e3e3e;
    font-size: rem(15px);
    @include mq($until: xl){
      font-size: rem(14px);
    }
    @include mq($until: md){
      font-size: rem(20px);
    }
    &:active, &--active, &--open {
      color: $color-main;
    }
  }

  &__hamburger-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}