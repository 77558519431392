.header__hamburger {
  display: none;
  width: 30px;
  height: 25px;
  position: relative;
  justify-self: flex-end;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    display: block;
  }

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $color-main;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 9px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 18px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  &--open {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 18px;
        left: 8px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    &__nav {
      position: absolute;
      width: 100%;
      top: 100%;
      right: 0;
      height: auto;
      background: $color-main;
      z-index: 99;
      transform-origin: 0% 0%;
      transform: translate(100%, 0);
      transition: transform .5s ease-in-out;
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: flex-start;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
      border-bottom: solid 3px #fff;

      &--visible {
        transform: translate(0, 0);
      }
    }

    &__menuLink {
      color: #fff;
    }

    &__menuList {
      flex-direction: column;
      padding: 10px 0px;
      position: static;
      align-items: flex-start;

      & > .header__menuItem {
        display: block;
        margin: 0 0 15px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__submenu {
      position: static;
      padding: 10px 0 0 0;
      margin-top: 5px;
      background: lighten($color-main, 10%);

      &--hidden {
        display: none;
      }

      .header__menuItem {
        margin: 0;
      }

      .header__menuLink {
        margin: 0 10px 10px 10px;
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}