.button {
  display: inline-block;
  padding: 8px 30px;
  letter-spacing: 0.5px;
  color: $color-font-light;
  background-color: $color-main;
  border: none;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  cursor: pointer;
  text-align: center;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;

  &:hover, &--active {
    background-color: $color-extra;
    color: $color-font-light;
  }

  &--secondary{
    background-color: $color-extra;
    &:hover {
      background-color: $color-main;
    }
  }
  &--white{
    background-color: #fff;
    color: $color-font-dark;
    &:hover, &--active {
      background-color: $color-main;
      color: #fff;
    }
  }
  &--withIcon{
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    font-size: rem(14px);
    width: auto;
    white-space: nowrap;

    img {
      margin-left: 8px;
      height: 25px;
    }
  }


}