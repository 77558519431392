.user-account{
  display: flex;
  align-items: flex-end;
  position: relative;

  @include mq($until: md){
    align-items: center;
  }

  &__link{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-extra;
    height: 45px;
    padding: 0px 13px;
    @include mq($until: lg){
      padding: 0px 10px;
    }
    img{
      width: 20px;
    }
  }
  &__menu{
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: $color-extra;
    padding: 20px 15px;
    width: 149px;
    text-align: right;
    z-index: 1;
    @include mq($until: lg){
      width: 125px;
    }
    @include mq($until: md){
      top: 63px;
      width: 134px;
    }
    @include mq($until: xs){
      top: 56px;
    }
  }
  &__item{
    display: inline-block;
    color: $color-font-light;
    margin-top: 15px;
    font-size: rem(16px);
    &:first-of-type{
      margin-top: 0;
    }
    &:hover{
      color: $color-font-extra;
    }
  }
}