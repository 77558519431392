.footer {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 30px;
    @include mq($until: xs){
      justify-content: flex-start;
    }
  }

  &__logo{
    @include mq($until: xs){
      display: none;
    }
  }

  &__account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: rem(600px);
    @include mq($until: sm) {
      flex-direction: column;
      align-items: flex-end;
    }
    @include mq($until: xs) {
      align-items: center;
    }
    h4 {
      margin: 0;
      color: $color-font-dark;
    }
    .button{
      margin-bottom: 10px;
      @include mq($until: sm) {
        min-width: 140px;
      }
    }
  }

  &__content {
    background-color: $color-main;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 30px;
    padding-top: 100px;
    padding-bottom: 100px;

    @include mq($until: xl) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    @include mq($until: lg) {
      grid-template-columns: repeat(8, 1fr);
    }
    @include mq($until: md){
      grid-column-gap: 10px;
    }

    @include mq($until: xs){
      padding-top: 30px;
      padding-bottom: 30px;
    }

    p {
      margin-bottom: 5px;
    }

    strong {
      letter-spacing: 0.3px;
    }

    p, a, span {
      color: #fff;
      font-size: rem(14px);
    }

    a:hover {
      color: $color-font-extra;
    }
  }

  &__header {
    margin-bottom: 30px;
    color: #fff;
    @include mq($until: xl) {
      margin-bottom: 10px;
    }
  }

  &__contact {
    grid-column-start: 1;
    grid-column-end: 3;
    @include mq($until: lg) {
      grid-column-end: 4;
    }
    @include mq($until: sm){
      grid-column-end: 5;
    }
    @include mq($until: xs){
      grid-column-start: 1;
      grid-column-end: 9;
    }
  }

  &__payment {
    grid-column-start: 3;
    grid-column-end: 5;
    @include mq($until: lg){
      grid-column-start: 4;
      grid-column-end: 7;
    }
    @include mq($until: sm){
      grid-column-start: 5;
      grid-column-end: 9;
    }
    @include mq($until: xs){
      margin-top: 30px;
      grid-column-start: 1;
      grid-column-end: 9;
    }
  }

  &__category-list {
    grid-column-start: 6;
    grid-column-end: 7;
    @include mq($until: lg) {
      grid-column-start: 7;
      grid-column-end: 9;
    }
    @include mq($until: md){

    }
    @include mq($until: sm){
      display: none;
    }

    .category-list {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &__item{
        width: auto;
        &:first-of-type .category-list__name {
          margin-top: 0;
        }
      }

      &__name {
        margin-top: 10px;
        min-height: unset;

      }
    }
  }

  &__menu {
    grid-column-start: 7;
    grid-column-end: 8;
    @include mq($until: lg) {
      display: none;
    }

    .header__menuList {
      flex-direction: column;
      width: 100%;
    }

    .header__menuItem {
      margin-left: 0;
      line-height: 1.5;
      &:first-of-type{
        margin-top: 0;
      }
    }
  }

  &__small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;

    @include mq($until: sm){
      margin: 20px 0;
    }

    @include mq($until: xs){
      flex-direction: column;
    }

    &-links{
      @include mq($until: xs){
        margin-bottom: 15px;
      }
    }

    &-links a {
      margin-right: 15px;
      @include mq($until: sm){
        margin-right: 5px;
      }
    }

    .ssi {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include mq($until: xs){
        margin-top: 15px;
      }
      img {
        margin-left: 15px;
        @include mq($until: sm){
          margin-left: 5px;
        }
      }
    }
  }
}
