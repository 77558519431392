.product {
  display: grid;
  grid-template-columns: 295px 1fr 1fr;
  grid-gap: 75px;
  position: relative;

  @include mq($until: xl) {
    grid-template-columns: 200px 1.5fr 1fr;
    grid-gap: 30px;
  }

  @include mq($until: md){
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 2fr;
  }
  @include mq($until: xs){
    grid-template-columns: none;
    grid-template-rows: repeat(3,auto);
  }

  &__slider {
    position: relative;
    @include mq($until: xs){
      grid-row: 1;
      grid-column: 1;
      max-width: 330px;
      margin: 0 auto;
    }
  }

  &__images {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    @include mq($until: xl) {
      margin-bottom: 15px;
    }
  }

  &__thumbs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  &__thumb {
    cursor: pointer;
    width: 100%;
    transition: opacity .3s ease-in-out;

    &.active, &:hover {
      opacity: 0.7;
    }
  }

  &__content{
    @include mq($until: xs){
      grid-row: 2;
      grid-column: 1;
    }
  }

  &__name {
    font-weight: $fw-regular;
    @include mq($until: xxl) {
      font-size: rem(33px);
    }
  }

  &__description {
    margin-bottom: 35px;

    p {
      font-size: rem(14px);
    }
  }

  &__pdf {
    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h3 {
        margin: 0 0 0 15px;
        font-size: rem(20px);
        font-weight: $fw-regular;
      }
    }

    &-files {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-file {
      margin-right: 15px;
      padding: 10px;
      display: flex;
      color: $color-font-dark;
      align-items: center;
      transition: background-color .3s ease-in-out, color .3s ease-in-out;

      img {
        width: 20px;
        margin-left: 10px;
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }

      &:hover {
        background-color: #fff;
        color: $color-main;

        img {
          opacity: 1;
        }
      }
    }
  }

  &__right-content{
    @include mq($until: md){
      grid-area: 2/2/3/3;
    }
    @include mq($until: xs){
      grid-row: 3;
      grid-column: 1;
    }
  }

  &__price {
    background-color: #fff;
    padding: 15px 15px 30px 15px;
    margin-bottom: 30px;

    h3 {
      color: $color-font-dark;
      font-weight: $fw-regular;
      font-size: rem(21px);
      margin-bottom: 30px;

      .old-price {
        color: $color-font-extra;
        font-weight: $fw-bold;
        font-size: inherit;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: solid 2px #fff;
    }

    &-value {
      margin-left: 10px;
      color: $color-font-extra;
      font-size: rem(18px);
      font-weight: $fw-bold;

      &--old {
        color: $color-font-dark;
        text-decoration: line-through;
      }
    }

    .button {
      padding: 8px 60px;
      font-weight: $fw-bold;
    }
  }

  .properties {
    padding-bottom: 30px;
    border-bottom: solid 2px #fff;

    &__item {
      margin-bottom: 20px;
    }

    &__label {
      font-size: rem(14px);
      font-weight: $fw-bold;
      color: $color-font-dark;
      margin-bottom: 5px;
    }

    &__value {
      font-size: rem(14px);
      margin: 0;
    }
  }
}