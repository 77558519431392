.ajaxLoader{
  margin-bottom: 30px;
  width: 48px;
  display: none;
}
.message{
  background-color: $color-main;
  padding: 15px;
  margin-bottom: 30px;
  &--error{
    background-color: $color-font-extra;
  }
  p{
    color: #fff;
  }
}