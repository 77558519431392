.socials{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 30px;
    &__link{
        margin-left: 20px;
        &:hover .svg-icon{
            fill: $color-main;
        }
        .svg-icon{
            fill: $color-extra;
            transition: fill .3s ease-in-out;
        }
    }
}