.carousel {
    &-caption {
        right: 0;
        top: 0;
        bottom: 0;
        left: 50%;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include mq($until: sm) {
            position: static;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        h1 {
            font-weight: $fw-regular;
            @include mq($until: md) {
                font-size: rem(22px);
                margin-bottom: 5px;
            }
        }

        h2 {
            font-weight: $fw-regular;
        }

        p {
            font-size: rem(14px);
            font-style: italic;
            color: $color-font-dark;
            @include mq($until: md) {
                font-size: rem(12px);
                margin-bottom: 5px;
            }
        }
    }

    &-indicators {
        justify-content: flex-end;
        margin: 0;
        padding: 25px 30px;
        bottom: 0;
        @include mq($until: md) {
            padding: 15px 20px;
        }
        @include mq($until: xs) {
            padding: 5px;
        }

        li {
            text-indent: -9999px;
            cursor: pointer;
            background-color: white;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            transition: background-color .3s ease-in-out;
            list-style: none;

            &:hover {
                background-color: $color-font-dark;
            }

            &.active {
                background-color: $color-font-extra;
            }
        }
    }

    &-item {
        @include mq($until: sm) {
            flex-direction: column;
        }

        &.active, &-prev, &-next {
            @include mq($until: md) {
                display: flex;
            }
        }
    }
    &-control-prev, &-control-next{
        background: rgba(0,0,0,0.1);
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
        bottom: unset;
        left: 15px;
        @include mq($until: sm) {
            left: 5px;
            width: 30px;
            height: 30px;
        }
    }
    &-control-next{
        left: unset;
        right: 15px;
        @include mq($until: sm) {
           right: 5px;
        }
    }
}