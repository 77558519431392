.category-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  @include mq($until: md) {
    display: none;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mq($until: md) {
      width: 15%;
    }
    @include mq($until: sm) {
      width: 33%;
      margin-bottom: 25px;
    }
    @include mq($until: xs){
      width: 50%;
    }

    //&:hover {
    //  .category-list__name {
    //    color: $color-font-extra;
    //  }
    //}
  }

  &__img {
    max-height: 30px;
    @include mq($until: md) {
      max-height: 45px;
    }
  }

  &__name {
    transition: color .3s ease-in-out;
    margin-top: 5px;
    color: $color-main;
    font-size: rem(12px);
    @include mq($until: md) {
      font-size: rem(12px);
      text-align: center;
      min-height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    @include mq($until: sm){
      min-height: unset;
    }
  }
}