._ajax_create_order{
  h4{
    margin-bottom: 30px;
  }
}

.buttons{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .button:nth-child(2){
    margin-left: 10px;
    margin-right: 10px;
  }
}

.checkoutTypeWrapper{
  display: none;
}

.accept-terms{
  background-color: #e8e8e8;
  padding: 15px;

  &__text{
    font-size: rem(13px);
  }

  .input{
    margin-bottom: 0;
  }
}