.cart-mini{
  position: absolute;
  top: 75px;
  right: 0;
  width: auto;
  min-width: 500px;
  background-color: $color-main;
  z-index: 99;
  padding: 20px 15px;
  display: none;
  @include mq($until: sm){
    display: none !important;
  }
  &--empty{
    min-width: unset;
  }
  p, div, span, a {
    color: $color-font-light;
  }
  &__list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
  }
  &__empty{
    text-align: right;
    word-break: keep-all;
    white-space: nowrap;
  }
  &__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button--secondary:hover{
      background-color: #fff;
      color: $color-main;
    }
  }
  &__total{
    font-size: rem(18px);
  }
  &__delete-position{
    margin-top: auto;
    margin-left: auto;
  }
  &__delete-btn{
    color: #ffffff;
    border-color: $color-font-extra;
    background-color: $color-font-extra;
    &:hover{
      border-color: #fff;
      background-color: $color-font-extra;
    }
  }
}

.cart-item{
  width: 100%;
  &::before{
    display: none;
  }
  &__information{
    padding: 15px;
  }
  &__link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:hover{
      .cart-item__name{
        color: #000000;
      }
    }
  }
  &__name{
    color: $color-font-light;
    font-size: rem(16px);
    transition: color .3s ease-in-out;
    font-weight: 400;
  }
}