// =========================
// Fonts
// =========================

 @font-face {
     font-family: 'Poppins';
     font-weight: 400;
     src: url('../../fonts/Poppins-Regular.ttf');
 }
@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('../../fonts/Poppins-SemiBold.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('../../fonts/Poppins-Bold.ttf');
}
