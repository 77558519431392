.cart {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;

  @include mq($until: md){
    margin-bottom: 0px;
  }

  &__table {
    display: flex;
    flex-direction: column;
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-title{
      margin-bottom: 0;
    }
    &-action{
      @include mq($until: md) {
        display: none;
      }
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 2.5fr 1fr 1.5fr 1.5fr 1fr;
    grid-gap: 50px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 30px;
    @include mq($until: xxl) {
      grid-gap: 30px;
    }
    @include mq($until: xl) {
      grid-gap: 15px;
    }
    @include mq($until: lg) {
      padding: 10px;
    }
    @include mq($until: md) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
    }
    @include mq($until: sm){
     grid-gap: 15px;
    }
    @include mq($until: xs){
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq($until: xs){
        margin: 10px 0;
      }
    }
  }

  &__img-wrap {
    display: flex;
    align-items: center;
  }

  &__product-name {
    text-align: left;
    justify-content: flex-start;
    @include mq($until: md) {
      grid-column: 2/5;
      font-size: rem(18px);
    }
    @include mq($until: xs){
      font-size: 16px;
    }
  }

  &__total-price {
    justify-content: flex-start;
    &-label {
      font-weight: $fw-bold;
      margin-right: 5px;
    }
    span{
      @include mq($until: xs){
        font-size: 14px;
      }
    }
  }

  &__qty {
    @include mq($until: sm){
      grid-column: 2/4;
    }
    @include mq($until: xs){
      margin: 20px 0;
    }
  }

  &__price{
    @include mq($until: sm){
      grid-column: 1;
      justify-content: flex-start;
    }
    span{
      @include mq($until: xs){
        font-size: 14px;
      }
    }
  }
  &__delete-position{
    @include mq($until: sm){
      grid-column: 4;
      grid-row: 2;
    }
    @include mq($until: xs){
      margin: 0;
      align-self: flex-end;
    }
    .btn-outline-secondary {
      color: $color-font-extra;
      border-color: $color-font-extra;
      &:hover{
        background-color: #f4f4f5;
      }
    }
  }

  &__total {
    margin-top: 30px;
    @include mq($until: lg) {
      margin-top: 0px;
    }

    &-position, &-shipping {
      margin-bottom: 5px;

      &-title {
        font-size: rem(18px);
        font-weight: $fw-bold;
        margin-right: 5px;
      }

      &-price-value, &-price-currency {
        font-size: rem(18px);
      }

      &-old-price {
        text-decoration: line-through;
        margin-right: 5px;
      }
    }

    &-price {
      @include mq($until: sm){
       grid-column: 1/3;
      }
      &-title {
        font-size: rem(22px);
        font-weight: $fw-bold;
        color: $color-font-extra;
        margin-right: 10px;
      }

      &-price-value, &-price-currency {
        font-size: rem(20px);
        font-weight: $fw-bold;
        color: $color-font-extra;
      }

      &-old-price {
        text-decoration: line-through;
        margin-right: 5px;

        &-value, &-currency {
          font-size: rem(18px);
        }
      }
    }
  }
}

.product-detailed__btn-qty-input {
  padding: 5px;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  text-align: center;
  @include mq($until: xl) {
    max-width: 50px;
  }
}