hr.white{
    background-color: #fff;
    margin-top: 50px;
    @include mq($until: md){
      margin-top: 30px;
    }
}
.homepage{
    &__category-list{
        margin: 15px 0;
        @include mq($until: md){
          margin: 15px 0;
        }
    }
    &__product-list{
        margin: 50px 0;
    }
    &__banner{
        background-image: url('../../images/homepage/banner.jpg');
        background-size: cover;
        background-position: center;
        height: 43vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding-bottom: 5vw;

        @include mq($until: xs){
          height: 85vw;
        }

        &-text{
            background: #146da9eb;
            padding: 20px;
        }

        h2{
            color: $color-font-light;
            font-size: rem(40px);
            text-shadow: 0 0 20px rgba(0,0,0,0.2);
            max-width: 870px;
            @include mq($until: lg){
              font-size: rem(30px);
              max-width: 400px;
            }
        }
        .button{
            font-weight: $fw-bold;
            color: $color-main;
            &:hover{
                color: #fff;
            }
        }
    }
}
