.breadcrumbs{
  display: flex;
  border-top: solid 2px #fff;
  border-bottom: solid 2px #fff;
  padding: 15px 0;
  margin-bottom: 0;
  flex-wrap: wrap;

  &__item{
    margin: 0;
    padding: 0;
    &::before{
      display: none;
    }
  }
  &__link{
    color: #acacad;
    &:hover{
      color: $color-main;
    }
  }

  &__break{
    padding: 0 5px;
    color: #acacad;
  }

}