._ajax_cart_link_header_wrapper{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  @include mq($until: md){
    align-items: center;
  }
}
.cart-header{
  background-color: $color-main;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;
  height: 45px;
  &__icon{
    width: 32px;
    margin-left: 15px;
  }
  &__counter{
    font-size: rem(18px);
    color: $color-font-light;
    min-width: 11px;
  }
}