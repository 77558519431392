.my-orders{
  display: flex;
  flex-direction: column;
  width: 100%;
  &__head, &__values{
    display: grid;
    grid-template-columns: repeat(4,1fr);
  }
  &__title{
    margin: 0;
    padding: 0 15px;
    &:first-of-type{
      padding: 0;
    }
  }
  &__head{
    margin: 30px 0;
    @include mq($until: sm){
      margin-bottom: 15px;
    }
    @include mq($until: xs){
      display: none;
    }
  }
  &__values{
    transition: background-color .1s ease-in-out;
    &:nth-child(even){
      background-color: #fff;
    }
    &:hover{
      background-color: #ccc;
    }
    @include mq($until: xs){
      display: flex;
      flex-direction: column;
      padding: 5px;
    }
  }
  &__value{
    padding: 20px;
    font-size: rem(18px);
    @include mq($until: sm){
      padding: 10px;
      font-size: rem(16px);
    }
    @include mq($until: xs){
      padding: 5px;
      font-size: 14px;

      &:first-of-type{
        font-weight: 600;
        &::before{
          content: 'Zamówienie nr. ';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
}