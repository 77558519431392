.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  :focus{outline: none;}

  .input-effect {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 30px;
  }

  .input {
    margin-top: 5px;
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #505052;
    background-color: transparent;
    position: relative;
    width: 100%;
    font-size: 1.6rem;

    & ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-main;
      transition: 0.4s;
    }

    &:focus ~ .focus-border {
      width: 100%;
      transition: 0.4s;
    }

    & ~ label  {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0px;
      color: #505052;
      transition: 0.3s;
      z-index: -1;
      letter-spacing: 0.5px;
    }

    &:focus ~ label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }
  }

  .has-content.input ~ .focus-border {
    width: 100%;
    transition: 0.4s;
  }

  .has-content.input ~ label {
    top: -16px;
    font-size: 12px;
    color: $color-main;
    transition: 0.3s;
  }

  textarea {
    margin-bottom: -7px;
  }

  .form-button {
    margin-top: 30px;
    transition: all .25s;
    border: 1px solid #505052;
    display: inline-block;
    color: #505052;
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    width: 100px;
    font-size: 1.4rem;

    &:hover {
      background-color: $color-main;
      color: white;
      cursor: pointer;
      border: 1px solid white;
    }
  }

  label  {
    width: 100%;
    color: #505052;
    transition: 0.3s;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 10px;
  }

  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }
}

.input-checkbox {
  display: grid;
  grid-template-columns: min-content 30%;
  column-gap: 1rem;

  @media screen and (max-width:991px) {
    grid-template-columns: min-content 40%;
  }

  @media screen and (max-width:575px) {
    grid-template-columns: min-content 60%;
  }

  label {
    font-size: 80%;
  }
}

.rodo {
  font-size: 90%;
  width: 60%;
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;

  & > input {
    margin-top: 10px;
    margin-right: 15px;
  }

  & > label {
    margin-bottom: 0;
  }

  @media screen and (max-width:991px) {
    width: 80%;
  }
}

#topic {
  font-size: 1.6rem;
}

.close {

  &:hover {
    cursor: pointer;
  }
}
