.product-list-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  @include mq($until: md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq($until: xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.product-card {
  display: flex;
  flex-direction: column;

  &__image {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 23vw;
    overflow: hidden;
    &:hover img{
      opacity: 0.9;
    }
    @include mq($until: md) {
      height: 40vw;
      justify-content: center;
    }
    @include mq($until: xs){
      height: 70vw;
    }
    img {
      width: auto;
      height: 100%;
      transition: opacity .3s ease-in-out;
    }
  }

  &__name {
    color: $color-font-dark;
    font-weight: $fw-bold;
    font-size: rem(18px);
    line-height: 1.3;
    margin-top: 30px;
    margin-bottom: 15px;
    display: block;
  }

  &__description {
    font-size: rem(13px);
    font-weight: $fw-regular;

    p {
      font-size: rem(13px);
      font-weight: $fw-regular;
    }
  }

  &__footer {
    margin-top: auto;
    display: grid;
    grid-template-columns: auto 50px;
    grid-template-rows: repeat(2, auto);
  }

  &__price-wrapper {
    margin: 15px 0;
    width: auto;
    position: relative;
  }

  &__price-cart{
    display: inline-block;
    &:hover .product-card{
      &__cart{
        opacity: 1;
        z-index: 2;
      }
      &__price{
        opacity: 0;
      }
    }
  }

  &__price {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    transition: opacity .3s ease-in-out;

    span {
      font-size: rem(18px);
      font-weight: $fw-semi-bold;
      color: $color-font-dark;
      margin-left: 10px;
    }

    &-old {
      text-decoration: line-through;
    }
  }

  &__cart{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
    background-color: #fff;
    padding: 5px 15px 5px 10px;
    min-width: 125px;
    img{
      max-height: 28px;
    }
    span{
      color: $color-main;
      position: relative;
      top: 2px;
      font-weight: 600;
    }
  }

  &__version {
    color: $color-main;
    font-size: rem(14px);
    text-align: left;
    margin-top: 7px;
    display: block;
  }

  &__wish {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ._ajax_toggle_wish_list {
      .fas.fa-heart {
        display: none;
      }

      &.active {
        .fas.fa-heart {
          display: block;
        }

        .far.fa-heart {
          display: none;
        }
      }
    }
  }

  &__more {
    grid-area: 2 / 1 / 3 / 3;

    .button {
      width: 100%;
    }
  }

  &__add{
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 10px;

    .button {
      width: 100%;
    }
  }
}